import React from "react";


class Background extends React.Component {
    render() {
        return (
            <div className="background"></div>
        );
    }
}

export default Background;