import React from "react";
import ProjectCard from "./ProjectCard";

function Projects() {
    return (
        <div className="projects">
            <div className="container p-4">
                <div className="row my-2">
                    <h1>Projects</h1>
                    <h4 className="font-weight-normal text-secondary">
                        Many of these projects have been passion projects, but some have been
                        purely for furthering my understanding of various programming concepts.
                    </h4>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row justify-content-center mt-3 mb-5">
                    <ProjectCard image="/images/Portfolio-preview.jpg"
                                 title="This website!"
                                 site="/"
                                //  repo="https://www.github.com/YM2992/YM2992.github.io/"
                                 badges={["React", "Bootstrap"]}>
                        A personal portfolio website to showcase my work.
                    </ProjectCard>
                    <ProjectCard image="/images/Raspberrify-preview.jpg"
                                 title="Raspberrify"
                                //  site="https://www.yasir.com.au/Raspberrify/"
                                 demo="https://youtu.be/h27pfMuOCmQ"
                                 repo="https://www.github.com/YM2992/Raspberrify/"
                                 badges={["NodeJS"]}>
                        A ‘now playing’ Spotify web application for Raspberry Pi.
                    </ProjectCard>
                    <ProjectCard image="/images/Pizza-bot-preview.jpg"
                                 title="Pizza Bot"
                                //  site="https://www.yasir.com.au/Pizza-Bot/"
                                 demo="https://youtu.be/gw3hdZJwwQM"
                                 repo="https://www.github.com/YM2992/Pizza-Bot/"
                                 badges={["Python"]}>
                        A bot that uses computer vision to automatically play the mobile game
                        <a href="https://goodpizzagreatpizza.com/"> Good Pizza, Great Pizza</a>.
                    </ProjectCard>
                    <ProjectCard image="/images/Sorting-visualizer-preview.jpg"
                                 title="Sorting Visualiser"
                                 site="https://www.yasir.com.au/Sorting-Visualiser/"
                                 repo="https://www.github.com/YM2992/Sorting-Visualiser/"
                                 badges={["JavaScript"]}>
                        A tool to demonstrate how various sorting algorithms work.
                    </ProjectCard>
                    {/* <ProjectCard image="https://via.placeholder.com/600x400"
                                 title="Turret"
                                //  site="https://www.yasir.com.au/Turret/"
                                 repo="https://www.github.com/YM2992/Turret/"
                                 badges={["Python", "C++"]}>
                        A turret that automatically tracks people and fires when locked on.
                    </ProjectCard> */}
                </div>
            </div>
        </div>
    );
}

export default Projects;