import React from "react";

class ProjectCard extends React.Component {
    render() {
        return (
            <div className={"col-xl-3 col-lg-5 col-md-5 p-0 m-3 project-card card bg-dark border-light " + this.props.className}>
                <img className="card-img-top" src={this.props.image} alt={this.props.image} />
                <div className="card-body">
                    <h5 className="card-title">
                        {this.props.title}
                    </h5>
                    <h6 className="card-subtitle mb-3">
                        {this.props.badges.map((item) => <span key={item} className={item + " badge badge-secondary mr-1"}>{item}</span>)}
                    </h6>

                    <p className="card-text">
                        {this.props.children}
                        {/* {this.props.description.map((item) => <span key={item}>{item}<br /></span>)} */}
                    </p>
                </div>
                <div className="card-footer border-light text-center">
                    {this.props.site != null &&
                        <a className="card-site float-left card-link" href={this.props.site}>View site</a>
                    }
                    {this.props.demo != null &&
                        <a className="card-demo card-link" href={this.props.demo}>View demo</a>
                    }
                    {this.props.repo != null &&
                        <a className="card-repo float-right card-link" href={this.props.repo}>View repo</a>
                    }
                </div>
            </div>
        );
    }
}

export default ProjectCard;