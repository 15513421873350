import React from "react";
import { useLocation } from "react-router-dom";

function PageNotFound() {
    let location = useLocation();
    console.log("Invalid page: " + location.pathname)

    return (
        <div className="pageNotFound">
            <div className="container">
                <div className="row align-items-center mx-2 my-5">
                    <div className="mx-auto col-lg-auto">
                        <img className="img-fluid"
                            src="https://http.cat/404"
                            alt="ERROR_CAT.jpg"
                        />
                    </div>

                    <div className="mx-auto col-lg-auto">
                        <h3>
                            The path <code>{location.pathname}</code> doesn't exist!
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;