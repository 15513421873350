import React from 'react';
import './index.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import {
    Background,
    Navigation,
    Home,
    About,
    Projects,
    Contact,
    
    PageNotFound
} from "./components";



function App() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Navigation />
            <Routes>
                <Route exact path="/background" element={<Background />} />
                {/* Handle valid routes */}
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/contact" element={<Contact />} />

                {/* Handle any exception routes */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    );
}

export default App;
