import React from "react";
import { useLocation } from "react-router-dom";
import {
    Navbar,
    Nav
} from "react-bootstrap";


// const easterEggMessages = ["whyisthisevenhere", "lookatthiscat", "beepboop", "boopbeep", "beepbeep", "boopboop", "randomstringofcharacters"];

function Navigation() {
    let location = useLocation();

    return (
        <div className="navigation">
            <Navbar collapseOnSelect expand="md" variant="dark">
                <Navbar.Brand href="/#/">Yasir Mustafa</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav className="mt-3" activeKey={"/#" + location.pathname}>
                        <Nav.Item className="mx-3">
                            <Nav.Link href="/#/" eventKey="/#/">/home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mx-3">
                            <Nav.Link href="/#/about">/about</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mx-3">
                            <Nav.Link href="/#/projects">/projects</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mx-3">
                            <Nav.Link href="/#/contact">/contact</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item className="mx-0">
                            <Nav.Link href={"/#/" + easterEggMessages[Math.floor(Math.random() * easterEggMessages.length)]}>/404</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Navigation;