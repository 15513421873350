import React from "react";
import { NavLink } from "react-router-dom";
import { FaGithub, FaLinkedinIn, FaRegEnvelope } from "react-icons/fa";

function Home() {
    return (
        <div className="home">
            <div className="container">
                <div className="row my-5 mx-2">
                    <h1 className="font-weight-light">
                        <b>Hi, i'm </b><b className="font-weight-bold">Yasir</b>
                    </h1>
                </div>

                <div className="row my-5 justify-content-center">
                    <div className="col-lg-9">
                        <h3 className="font-weight-normal">
                            I'm a <strong>Software Engineering</strong> student with a deep passion
                            for making things.
                        </h3>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row homeContacts justify-content-center align-items-center text-center my-2">
                    <div className="card col-3 col-xl-2 col-lg-2 col-md-3 col-sm-3 mx-2">
                        <a role="button" href="https://github.com/YM2992/">
                            <FaGithub className="p-1" />
                        </a>
                    </div>
                    <div className="card col-3 col-xl-2 col-lg-2 col-md-3 col-sm-3 mx-2">
                        <a role="button" href="mailto:">
                            <FaRegEnvelope className="p-1" />
                        </a>
                    </div>
                    <div className="card col-3 col-xl-2 col-lg-2 col-md-3 col-sm-3 mx-2">
                        <a role="button" href="https://www.linkedin.com/in/yasir-mustafa-b3a637218/">
                            <FaLinkedinIn className="p-1" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;