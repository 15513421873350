import React from "react";
import { NavLink } from "react-router-dom";

function About() {
    return (
        <div className="about">
            <div className="container p-4">
                <div className="row my-2">
                    <h1>About me</h1>
                </div>

                <div className="row mt-5">
                    <h2>Interests</h2>
                </div>

                <div className="row mt-1">
                    <h3 className="col-lg-auto font-weight-normal">
                        I am enthusiastic about programming and creating solutions to everyday problems using software.
                    </h3>
                    <img className="img-fluid"
                        src=""
                        alt="picture_of_me_bouldering.jpg"
                    />
                    <h3 className="col-lg-auto font-weight-normal">
                        In addition to software development, my downtime includes bouldering with mates.
                    </h3>
                </div>

                <div className="row mt-5">
                    <h2>Experience</h2>
                </div>
                <div className="row mt-1">
                    <h3 className="col-lg-auto font-weight-normal">
                        I have a proficient understanding of <strong>Object Oriented Programming</strong> in multiple languages
                        {" "}including <strong>Lua</strong>, <strong>Python</strong>
                        {" "} and <strong>Java</strong>.
                        <br />
                        <br />
                        However, most of my <NavLink className="text-warning" to="/projects">projects</NavLink> are developed with <strong>NodeJS</strong>.
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default About;