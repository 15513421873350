import React from "react";
import { FaGithub, FaLinkedinIn, FaRegEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";

function Contact() {
    return (
        <div className="contact">
            <div className="container">
                <div className="row m-5">
                    <h1 className="font-weight-normal mx-auto my-5">
                        You can reach me here!
                    </h1>
                </div>
                <div className="row card-deck contactsDeck align-items-center text-center mb-5">
                    <div className="card">
                        <a role="button" href="https://github.com/YM2992/" rel="noreferrer" target="_blank">
                            <FaGithub className="p-2" />
                        </a>
                    </div>
                    <div className="card">
                        <a role="button" href="https://www.linkedin.com/in/yasir-mustafa-b3a637218/" rel="noreferrer" target="_blank">
                            <FaLinkedinIn className="p-2" />
                        </a>
                    </div>
                    <div className="card">
                        <a role="button" href="mailto:" rel="noreferrer" target="_blank">
                            <FaRegEnvelope className="p-2" />
                        </a>
                    </div>
                    {/* <div className="card">
                        <a role="button" href="https://www.facebook.com/yasir.mustafa.7355079/" rel="noreferrer" target="_blank">
                            <FaFacebookF className="p-2" />
                        </a>
                    </div>
                    <div className="card">
                        <a role="button" href="https://www.instagram.com/_yasirm_/" rel="noreferrer" target="_blank">
                            <FaInstagram className="p-2" />
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Contact;